import { Link } from 'react-router-dom';
import StreamerList from '../../components/streamer-list/StreamerList';

import './Streamer.scss';

export default function PageStreamer() {

    return (
        <div className="page-streamer page-container">
            <div className="page-wrapper">
                <div className="page-streamer-header">
                    <Link className="btn-back" to="/" />
                    <div className="page-title" />
                </div>
                <div className="page-streamer-content">
                    <StreamerList mode="all"></StreamerList>
                </div>
            </div>
        </div>
    )
}