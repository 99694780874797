import { createPortal } from 'react-dom'
import "./Modal.scss";

export default function Modal(props) {
    const {
        visible = false,
        content,
    } = props;

    return createPortal(
        <div className={`modal-mask ${visible ? "visible" : ""}`}>{content}</div>
    , document.body);
}
