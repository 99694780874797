
export function formatDate(ts = Date.now()) {
    const date = new Date(ts);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return `${year}-${padZero(month + 1)}-${padZero(day)} ${padZero(hour)}:${padZero(minute)}:${padZero(second)}`
}

export function padZero(str, size = 2) {
    return str.toString().padStart(size, '0');
}

const SPECIAL_HTML_CHARS = /[&<>"'`]/g;
export function safe(str) {
    return str.replace(SPECIAL_HTML_CHARS, function (m) {
        return m === '&' ? '&amp;'
            :  m === '<' ? '&lt;'
            :  m === '>' ? '&gt;'
            :  m === '"' ? '&quot;'
            :  m === "'" ? '&#39;'
            :  /*m === '`'*/ '&#96;';
    });
}

export function isIOS() {
    return /ip[honead]{2,4}/i.test(window.navigator.userAgent) || (window.navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && 'ontouchend' in document);
}

export function isAndroid() {
    return /android[/\s-]?/i.test(window.navigator.userAgent);
}
