import axios from 'axios';
import {
    updateCommentAPI,
    deleteCommentAPI,
    interactionCommentAPI,
    forwardCommentAPI,
    listCommentAPI,
    listMyCommentAPI,
    listStreamerCommentAPI,
    listStreamerAPI,
    listCommentStreamerAPI
} from './constants';


function request(url, data) {
    console.log('API:', url);
    console.log('Request:', data);
    return new Promise((reslove, reject) => {
        axios.post(url, data).then(res => {
            console.log('Response:', res);
            if (res.data.err === 0) {
                reslove(res.data.data)
            } else {
                reject(res.data.err);
            }
        }).catch((err) => {
            console.error(err);
            reject(-1);
        })
    })
}

export function addComment(title, content) {
    return request(updateCommentAPI, {
        comment_id: 0,
        title,
        content
    })
}

export function editComment(comment_id, title, content) {
    return request(updateCommentAPI, {
        comment_id,
        title,
        content
    })
}

export function deleteComment(comment_id) {
    return request(deleteCommentAPI, {
        comment_id
    })
}

export function interactionComment(comment_id, emoji, click) {
    return request(interactionCommentAPI, {
        comment_id,
        emoji,
        click
    })
}

export function forwardComment(comment_id, streamer_id) {
    return request(forwardCommentAPI, {
        comment_id,
        streamer_id
    })
}

export function getCommentList(my = false, sort = 0, page = 0, limit = 10) {
    const data = {
        filter: {
            my,
        },
        sort: {},
        pagination: {
            page: page + 1,
            limit
        }
    };
    if (sort === '-1') {
        data.sort.hot = true;
    } else {
        data.sort.hot = false;
        data.sort.emoji = Number(sort)
    }
    return request(listCommentAPI, data);
}

export function getMyCommentList(status = 'all', page = 0, limit = 10) {
    const data = {
        filter: {},
        sort: {
            emoji: 0
        },
        pagination: {
            page: page + 1,
            limit
        }
    }
    if (status !== 'all') {
        data.filter.status = status;
    }
    return request(listMyCommentAPI, data);
}

export function getStreamerList(searchKey = '', platform = 'all', received = false, page = 0, limit = 15) {
    const data = {
        filter: {},
        sort: {
            received
        },
        pagination: {
            page: page + 1,
            limit
        }
    }
    if (searchKey !== '') {
        data.filter.nickname = searchKey;
    }
    if (platform !== 'all') {
        data.filter.platform = platform;
    }
    return request(listStreamerAPI, data);
}

export function getForwardStreamerList(comment_id, searchKey = '', platform = 'all', forward = 'all', received = 'all', page = 0, limit = 15) {
    const data = {
        comment_id,
        filter: {
            relay: forward
        },
        sort: {
            received
        },
        pagination: {
            page: page + 1,
            limit
        }
    }
    if (searchKey !== '') {
        data.filter.nickname = searchKey;
    }
    if (platform !== 'all') {
        data.filter.platform = platform;
    }
    return request(listCommentStreamerAPI, data);
}

export function getStreamerCommentList(streamer_id, my = false, sort = 0, page = 0, limit = 10) {
    const data = {
        streamer_id,
        filter: {
            my
        },
        sort: {},
        pagination: {
            page: page + 1,
            limit
        }
    }
    if (sort === '-1') {
        data.sort.hot = true;
    } else {
        data.sort.hot = false;
        data.sort.emoji = Number(sort)
    }
    return request(listStreamerCommentAPI, data);
}