
import { Link } from 'react-router-dom';
import CommentList from '../../components/comment-list/CommentList';

import './Comment.scss';

export default function PageComment() {

    return (
        <div className="page-comment page-container">
            <div className="page-wrapper">
                <div className="page-comment-header">
                    <div className="btn-exit" onClick={() => {
                        window._gs_info_.source.postMessage({
                            action: 'close'
                        }, window._gs_info_.target);
                    }} />
                    <div className="page-title" />
                    <Link className="btn-publish" to="/publish" />
                    {/* <Link className="btn-primary btn-streamer" to="/streamer">主播列表</Link> */}
                </div>
                <div className="page-comment-content">
                    <CommentList mode="all"></CommentList>
                </div>
            </div>
        </div>
    )
}