const SYSTEM_EXCEPTION_MSG = "系统异常，请稍后重试";

const ERROR_MSG = {
  1301: '留言已审核成功，无法删除',
  1302: '留言已审核成功，无法修改',
  1303: '留言标题最多只能输入25个字',
  1304: '留言内容最多只能输入1000个字',
  1305: '您已达到今日留言上限，请明日再来。',
  1306: '留言违规，无法删除',
  1307: '留言违规，无法修改',
};
// 获取错误消息
export function getErrorMsg(code) {
  return ERROR_MSG[code] || SYSTEM_EXCEPTION_MSG;
}
