import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.scss';

import PageComment from "./pages/comment/Comment";
import PageStreamer from "./pages/streamer/Streamer";
import PagePublish from "./pages/publish/Publish";

function App() {
    return (
        <Router>
            <div className="app">
                <Routes>
                    <Route exact path="/" element={<PageComment />} />
                    <Route path="/streamer" element={<PageStreamer />} />
                    <Route path="/publish" element={<PagePublish />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
