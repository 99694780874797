import { useState, useEffect, useCallback, useRef } from "react";
import Pager from "../pager/Pager";
import Loading from "../loading/Loading";
import ToggleBar from "../toggle-bar/ToggleBar";
import Modal from "../modal/Modal";
import message from '../message/Message';
import { getErrorMsg } from "../../api/message";
import CommentList from "../comment-list/CommentList";

import {
    forwardComment,
    getForwardStreamerList,
    getStreamerList,
} from "../../api/api";

import "./StreamerList.scss";

const PLATFROM_LIST = {
    DOUYIN: "douyin",
    BILIBILI: "bilibili",
    DOUYU: "douyu",
    KUAISHOU: "kuaishou",
    HUYA: "huya",
};
const PLATFROM_FILTER = [
    { key: "all", name: "全部" },
    { key: "douyin", name: "抖音" },
    { key: "bilibili", name: "哔哩哔哩" },
    { key: "douyu", name: "斗鱼" },
    { key: "kuaishou", name: "快手" },
    { key: "huya", name: "虎牙" },
];
const FORWARD_FILTER = [
    { key: "all", name: "全部" },
    { key: "true", name: "已投稿" },
    { key: "false", name: "未投稿" },
];
const RECEIVED_SORTER = [
    { key: "0", name: "最新发布" },
    { key: "1", name: "投稿最多" },
];

export default function StreamerList(props) {
    const { mode = "all", comment = null } = props;
    const [streamerList, setStreamerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [curPlatform, setCurPlatform] = useState(PLATFROM_FILTER[0].key);
    const [curForward, setCurForward] = useState(FORWARD_FILTER[0].key);
    const [curReceived, setCurReceived] = useState(RECEIVED_SORTER[0].key);
    const [commentModalVisible, setCommentModalVisible] = useState(false);
    const selectedStreamerRef = useRef(null);
    const scrollRef = useRef();

    const handleJump = (jumpPage) => {
        setPage(jumpPage);
    };
    const handleFilterPlatform = (key) => {
        setCurPlatform(key);
        setPage(0);
    };
    const handleFilterForward = (key) => {
        setCurForward(key);
        setPage(0);
    };
    const handleSortReceived = (key) => {
        setCurReceived(key);
        setPage(0);
    };
    const handleViewComments = (streamer) => {
        selectedStreamerRef.current = streamer;
        setCommentModalVisible(true);
    };
    const handleForwardComment = (streamer) => {
        setLoading(true);
        forwardComment(comment.comment_id, streamer.streamer_id).then(() => {
            message.info('已成功投稿主播');
            streamer.relay = true;
            streamer.received = streamer.received + 1;
            setStreamerList([...streamerList]);
            setLoading(false);
        }).catch(err => {
            message.info(getErrorMsg(err));
            setLoading(false);
        });
    };
    const handleCancelCommentsView = () => {
        selectedStreamerRef.current = null;
        setCommentModalVisible(false);
    };
    const handleClearSearch = () => {
        setSearchValue("");
        setSearchKey("");
    };
    const handleSearch = () => {
        setSearchKey(searchValue);
    };

    const fetchData = useCallback(
        (page, searchKey, curPlatform, curForward, curReceived) => {
            console.log(page, searchKey, curPlatform, curForward, curReceived);
            setLoading(true);
            let fetcher;
            if (mode === "forward") {
                fetcher = getForwardStreamerList(
                    comment.comment_id,
                    searchKey,
                    curPlatform,
                    curForward,
                    curReceived,
                    page
                );
            } else {
                fetcher = getStreamerList(
                    searchKey,
                    curPlatform,
                    curReceived,
                    page
                );
            }
            fetcher
                .then((res) => {
                    setStreamerList(res.streamers);
                    setTotal(res.pagination.total);
                    setLoading(false);
                    scrollRef.current.scrollTop = 0;
                })
                .catch((err) => {
                    message.info(getErrorMsg(err));
                    setLoading(false);
                });
        },
        [mode, comment?.comment_id]
    );

    useEffect(() => {
        fetchData(
            page,
            searchKey,
            curPlatform,
            curForward,
            curReceived === "1"
        );
    }, [page, searchKey, curPlatform, curForward, curReceived, fetchData]);

    const getPlatform = (streamer) => {
        switch (streamer.platform) {
            case PLATFROM_LIST.DOUYU: {
                return (
                    <div className="card-platfrom">
                        <div className="card-platfrom-icon icon-douyu" />
                        <span className="card-platfrom-name">斗鱼</span>
                    </div>
                );
            }
            case PLATFROM_LIST.DOUYIN: {
                return (
                    <div className="card-platfrom">
                        <div className="card-platfrom-icon icon-douyu" />
                        <span className="card-platfrom-name">抖音</span>
                    </div>
                );
            }
            case PLATFROM_LIST.KUAISHOU: {
                return (
                    <div className="card-platfrom">
                        <div className="card-platfrom-icon icon-kuaishou" />
                        <span className="card-platfrom-name">快手</span>
                    </div>
                );
            }
            case PLATFROM_LIST.BILIBILI: {
                return (
                    <div className="card-platfrom">
                        <div className="card-platfrom-icon icon-bilibili" />
                        <span className="card-platfrom-name">哔哩哔哩</span>
                    </div>
                );
            }
            case PLATFROM_LIST.HUYA: {
                return (
                    <div className="card-platfrom">
                        <div className="card-platfrom-icon icon-huya" />
                        <span className="card-platfrom-name">虎牙</span>
                    </div>
                );
            }
            default: {
                return (
                    <div className="card-platfrom">
                        <div className="card-platfrom-icon icon-unkown" />
                        <span className="card-platfrom-name">未知平台</span>
                    </div>
                );
            }
        }
    };

    const getAction = (streamer) => {
        if (mode === "all") {
            return (
                <div
                    className="btn-forward"
                    onClick={() => handleViewComments(streamer)}
                >
                    查看投稿
                </div>
            );
        } else if (streamer.relay) {
            return <div className="card-forward-tag">已投稿</div>;
        } else {
            return (
                <div
                    className="btn-forward"
                    onClick={() => handleForwardComment(streamer)}
                >
                    投稿给TA
                </div>
            );
        }
    };

    return (
        <div className="streamer-list-wrapper">
            <div className="streamer-list-header">
                <div className="streamer-searchbar">
                    <input
                        className="search-key"
                        type="text"
                        value={searchValue}
                        onChange={(ev) => {
                            setSearchValue(ev.target.value);
                        }}
                        onKeyDown={(ev) => {
                            if (ev.key === "Enter" && searchValue.length > 0) {
                                handleSearch();
                            }
                        }}
                    />
                    {searchValue.length > 0 && (
                        <div className="clear-btn" onClick={handleClearSearch}>
                            ×
                        </div>
                    )}
                    <div
                        className={`search-btn ${searchValue.length < 1 ? 'disabled' : ''}`}
                        onClick={handleSearch}
                    />
                </div>
                <div className="streamer-toolbar">
                    <ToggleBar
                        label="平台"
                        selectedKey={curPlatform}
                        items={PLATFROM_FILTER}
                        onChange={handleFilterPlatform}
                    />
                    {mode === "forward" && (
                        <ToggleBar
                            label="投稿"
                            selectedKey={curForward}
                            items={FORWARD_FILTER}
                            onChange={handleFilterForward}
                        />
                    )}
                    <ToggleBar
                        label="排序"
                        selectedKey={curReceived}
                        items={RECEIVED_SORTER}
                        onChange={handleSortReceived}
                    />
                </div>
            </div>

            <div className="streamer-list-container" ref={scrollRef}>
                <div className="streamer-list">
                    {(streamerList || []).map((streamer) => (
                        <div
                            key={streamer.streamer_id}
                            className="streamer-card"
                        >
                            <div className="card-header">
                                {getPlatform(streamer)}
                            </div>
                            <div className="card-content">
                                <div className="card-name">
                                    {streamer.nickname}
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="card-info">
                                    收到投稿{streamer.received}次
                                </div>
                                {getAction(streamer)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="streamer-list-footer">
                { total > 0 && <Pager page={page} total={total} perPage={15} onJump={handleJump} /> }
            </div>
            {loading && <Loading />}
            {mode === "all" && selectedStreamerRef.current && (
                <Modal
                    visible={commentModalVisible}
                    content={
                        <div className="comment-modal">
                            <div className="comment-modal-header">
                                <div
                                    className="btn-close"
                                    onClick={() => {
                                        handleCancelCommentsView();
                                    }}
                                />
                                <div className="page-title" />
                            </div>
                            <div className="comment-modal-body">
                                <CommentList
                                    mode="streamer"
                                    streamer={selectedStreamerRef.current}
                                ></CommentList>
                            </div>
                        </div>
                    }
                ></Modal>
            )}
        </div>
    );
}
