import './Pager.scss';

export default function Pager(props) {
    const { page, total, onJump, perPage = 10, showPageNum = 5 } = props;
    const maxPage = Math.ceil(total / perPage) - 1;
    const isFirst = page === 0;
    const isLast = page === maxPage;
    const sideNum = Math.floor((showPageNum - 1) / 2);
    let start, end;
    if (page < sideNum) {
        start = 0;
        end = Math.min(showPageNum - 1, maxPage);
    } else if (page > maxPage - sideNum) {
        start = Math.max(0, maxPage - showPageNum + 1);
        end = maxPage;
    } else {
        start = Math.max(0, page - sideNum);
        end = Math.min(page + sideNum);
    }
    const prevPages = [];
    const nextPages = [];
    for (let i = start; i <= page - 1; i++) {
        prevPages.push(i);
    }
    for (let i = page + 1; i <= end; i++) {
        nextPages.push(i);
    }

    return (
        <div className="pager">
            {/* {isFirst ? (
                <div className="pager-anchor disabled">首页</div>
            ) : (
                <div className="pager-anchor" onClick={() => onJump(0)}>首页</div>
            )} */}
            {!isFirst && (
                <div className="pager-anchor" onClick={() => onJump(page - 1)}>上一页</div>
            )}
            {prevPages.map(p => <div key={p} className="pager-anchor" onClick={() => onJump(p)}>{p + 1}</div>)}
            <div className="pager-anchor current">{page + 1}</div>
            {nextPages.map(p => <div key={p} className="pager-anchor" onClick={() => onJump(p)}>{p + 1}</div>)}
            {!isLast && (
                <div className="pager-anchor" onClick={() => onJump(page + 1)}>下一页</div>
            )}
            {/* {isLast ? (
                <div className="pager-anchor disabled">尾页</div>
            ) : (
                <div className="pager-anchor" onClick={() => onJump(maxPage)}>尾页</div>
            )} */}
        </div>
    );
}
