import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

import App from './App';
import interceptors from './api/interceptor.js';
import reportWebVitals from './reportWebVitals';

import './index.css';

if (window._gs_info_ && window._gs_info_.data && window._gs_info_.data.token) {
    boot(window._gs_info_.data.token);
} else {
    const timer = setInterval(() => {
        if (window._gs_info_ && window._gs_info_.data && window._gs_info_.data.token) {
            boot(window._gs_info_.data.token);
            clearInterval(timer);
        }
    }, 500)
}

function boot(token) {
    console.log('boot with token', token);
    interceptors(axios, token);
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<App />);
    reportWebVitals();
}
