import "./ToggleBar.scss";

export default function ToggleBar(props) {
    const { label, items, selectedKey, onChange } = props;
    const curKey = selectedKey || (items.length > 0 ? items[0].key : null);
    const handleClick = (item) => {
        onChange(item.key, curKey);
    };

    return (
        <div className="toggle-bar list-filter-bar">
            {label && <div className="toggle-intro">{label}</div>}
            <div className="toggle-group">
                {items.map((item) => (
                    <div
                        key={item.key}
                        className={`toggle-btn ${
                            curKey === item.key ? "actived" : ""
                        }`}
                        onClick={() => {
                            handleClick(item);
                        }}
                    >
                        {item.name}
                    </div>
                ))}
            </div>
        </div>
    );
}
