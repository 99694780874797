import { useState, useEffect, useCallback, useRef } from "react";
import { formatDate } from "../../utls";
import Pager from "../pager/Pager";
import Loading from "../loading/Loading";
import ToggleBar from "../toggle-bar/ToggleBar";
import Modal from "../modal/Modal";
import StreamerList from "../streamer-list/StreamerList";
import message from '../message/Message';
import { getErrorMsg } from "../../api/message";
import {
    addComment,
    deleteComment,
    editComment,
    getCommentList,
    getMyCommentList,
    getStreamerCommentList,
    interactionComment,
} from "../../api/api";
import "./CommentList.scss";

const PLATFROM_LIST = {
    DOUYIN: "douyin",
    BILIBILI: "bilibili",
    DOUYU: "douyu",
    KUAISHOU: "kuaishou",
};
const COMMENT_STATUS = {
    PENDING: "pending",
    APPROVED: "approved",
    DENIED: "denied",
    BLOCKED: "blocked",
};
const SENDER_FILTER = [
    { key: "all", name: "全部留言" },
    { key: "my", name: "我的留言" },
];
const STATUS_FILTER = [
    { key: "all", name: "全部" },
    { key: "pending", name: "审核中" },
    { key: "approved", name: "审核通过" },
    { key: "denied", name: "审核不通过" },
    // { key: "blocked", name: "违规" },
];
const EMOJI_SORTER = [
    { key: "-1", name: "热度最高" },
    { key: "0", name: "最新发布" },
    { key: "1", name: "爱心最多" },
    { key: "4", name: "拥抱最多" },
    { key: "3", name: "点赞最多" },
    { key: "2", name: "笑哭最多" },
];
const EMOJI_ORDER = [1, 4, 3, 2];

export default function CommentList(props) {
    const { mode = "all", streamer = null } = props;
    const [commentList, setCommentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [curSender, setCurSender] = useState(SENDER_FILTER[0].key);
    const [curStatus, setCurStatus] = useState(STATUS_FILTER[0].key);
    const [curEmoji, setCurEmoji] = useState(EMOJI_SORTER[0].key);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [forwardModalVisible, setForwardModalVisible] = useState(false);
    const selectedCommentRef = useRef(null);
    const forwardCommentRef = useRef(null);
    const [editTitle, setEditTitle] = useState("");
    const [editContent, setEditContent] = useState("");
    const [addTitle, setAddTitle] = useState("");
    const [addContent, setAddContent] = useState("");
    const [editTitleError, setEditTitleError] = useState(false);
    const [editContentError, setEditContentError] = useState(false);
    const [addTitleError, setAddTitleError] = useState(false);
    const [addContentError, setAddContentError] = useState(false);
    const scrollRef = useRef();

    const handleJump = (jumpPage) => {
        setPage(jumpPage);
    };
    const handleFilterSender = (key) => {
        setCurSender(key);
        setPage(0);
    };
    const handleFilterStatus = (key) => {
        setCurStatus(key);
        setPage(0);
    };
    const handleSortEmoji = (key) => {
        setCurEmoji(key);
        setPage(0);
    };
    const handleInteractive = (comment, key, emoji) => {
        if (emoji.click) {
            emoji.click = false;
            emoji.count = emoji.count - 1;
        } else {
            emoji.click = true;
            emoji.count = emoji.count + 1;
        }
        setCommentList([...commentList]);
        interactionComment(comment.comment_id, parseInt(key, 10), !emoji.click).catch(err => {
            message.info(getErrorMsg(err));
            setLoading(false);
        });
    };
    const handleEdit = (comment) => {
        selectedCommentRef.current = comment;
        setEditTitle(comment.title);
        setEditContent(comment.content);
        setEditModalVisible(true);
    };
    const handleDelete = (comment) => {
        selectedCommentRef.current = comment;
        setDeleteModalVisible(true);
    };
    // const handleForward = (comment) => {
    //     forwardCommentRef.current = comment;
    //     setForwardModalVisible(true);
    // };
    const handleConfirmDelete = () => {
        setDeleteModalVisible(false);
        setLoading(true);
        deleteComment(selectedCommentRef.current.comment_id).then(() => {
            selectedCommentRef.current = null;
            fetchData(page, curSender === "my", curStatus, curEmoji);
        }).catch(err => {
            message.info(getErrorMsg(err));
            setLoading(false);
        });
    };
    const handleCancelDelete = () => {
        selectedCommentRef.current = null;
        setDeleteModalVisible(false);
    };
    const handleConfirmEdit = () => {
        setEditModalVisible(false);
        setLoading(true);
        editComment(
            selectedCommentRef.current.comment_id,
            editTitle,
            editContent
        ).then(() => {
            message.info('留言修改成功，请耐心等待审核。');
            selectedCommentRef.current.title = editTitle;
            selectedCommentRef.current.content = editContent;
            selectedCommentRef.current.status = COMMENT_STATUS.PENDING;
            selectedCommentRef.current = null;
            setCommentList([...commentList]);
            setEditTitle("");
            setEditContent("");
            setEditTitleError(false);
            setEditTitleError(false);
            fetchData(page, curSender === "my", curStatus, curEmoji);
        }).catch(err => {
            message.info(getErrorMsg(err));
            setLoading(false);
        });
    };
    const handleCancelEdit = () => {
        selectedCommentRef.current = null;
        setEditContent("");
        setEditModalVisible(false);
    };
    const handleCancelForward = () => {
        forwardCommentRef.current = null;
        setForwardModalVisible(false);
    };
    const handleConfirmAdd = () => {
        setLoading(true);
        addComment(addTitle, addContent).then(() => {
            message.info('谢谢你的分享，如果喜欢我们的游戏也请在Steam上给个好评吧🥺', 3000);
            setPage(0);
            setCurStatus("all");
            setAddTitle("");
            setAddContent("");
            setAddTitleError(false);
            setAddTitleError(false);
            fetchData(page, curSender === "my", curStatus, curEmoji);
        }).catch(err => {
            message.info(getErrorMsg(err));
            setLoading(false);
        });
    };

    const fetchData = useCallback(
        (page, isMy, curStatus, curEmoji) => {
            console.log(page, isMy, curStatus, curEmoji);
            setLoading(true);
            let fetcher;
            if (mode === "my") {
                fetcher = getMyCommentList(curStatus, page);
            } else if (mode === "streamer") {
                fetcher = getStreamerCommentList(
                    streamer.streamer_id,
                    isMy,
                    curEmoji,
                    page
                );
            } else {
                fetcher = getCommentList(isMy, curEmoji, page);
            }
            fetcher
                .then((res) => {
                    setCommentList(res.comments);
                    setTotal(res.pagination.total);
                    setLoading(false);
                    scrollRef.current.scrollTop = 0;
                })
                .catch((err) => {
                    message.info(getErrorMsg(err));
                    setLoading(false);
                });
        },
        [mode, streamer?.streamer_id]
    );

    useEffect(() => {
        fetchData(page, curSender === "my", curStatus, curEmoji);
    }, [page, curSender, curStatus, curEmoji, fetchData]);

    const getStatusLabel = (comment) => {
        switch (comment.status) {
            case COMMENT_STATUS.PENDING: {
                return <span className="card-status pending" />;
            }
            case COMMENT_STATUS.APPROVED: {
                return <span className="card-status approved" />;
            }
            case COMMENT_STATUS.DENIED: {
                return <>
                    <span className="card-status denied" />
                    { comment.reason && <span className="blocked-reason-trigger">查看原因</span> }
                    { comment.reason && <span className="blocked-reason-message">{comment.reason}</span> }
                </>;
            }
            case COMMENT_STATUS.BLOCKED: {
                return <span className="card-status blocked" />;
            }
            default: {
                return null;
            }
        }
    };

    const getActionList = (comment) => {
        switch (comment.status) {
            case COMMENT_STATUS.PENDING:
            case COMMENT_STATUS.DENIED: {
                return (
                    <div className="card-toolbar">
                        <div
                            className="btn-action btn-normal"
                            onClick={() => handleEdit(comment)}
                        >
                            编辑留言
                        </div>
                        <div
                            className="btn-action btn-danger"
                            onClick={() => handleDelete(comment)}
                        >
                            删除留言
                        </div>
                    </div>
                );
            }
            case COMMENT_STATUS.APPROVED: {
                return (
                    <div className="card-toolbar">
                        {/* <div className="card-forward-info">
                            已投稿主播{`(${comment.streamer})`}
                        </div>
                        <div
                            className="btn-action btn-done"
                            onClick={() => handleForward(comment)}
                        >
                            投稿
                        </div> */}
                    </div>
                );
            }
            case COMMENT_STATUS.BLOCKED: {
                return (
                    <div className="card-toolbar">
                        <div
                            className="btn-action btn-danger"
                            onClick={() => handleDelete(comment)}
                        >
                            删除留言
                        </div>
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    };

    const getEmojiList = (comment) => {
        if (comment.status !== COMMENT_STATUS.APPROVED) {
            return <div className="card-feedback-bar"></div>;
        }
        const emojiList = [];
        EMOJI_ORDER.forEach(order => {
            emojiList.push([ order, comment.emoji[order] ]);
        })
        return (
            <div className="card-feedback-bar">
                {emojiList.map(([key, item]) => (
                    <div
                        key={key}
                        className={`card-feedback-btn ${
                            item.click ? "actived" : ""
                        }`}
                        onClick={() => {
                            handleInteractive(comment, key, item);
                        }}
                    >
                        <div className={`card-feedback-icon icon-${key}`} />
                        <div className="card-feedback-amount">{item.count}</div>
                    </div>
                ))}
            </div>
        );
    };

    const getPlatform = (streamer) => {
        switch (streamer.platform) {
            case PLATFROM_LIST.DOUYU: {
                return (
                    <div className="streamer-platfrom douyu">
                        <img
                            className="streamer-platfrom-icon"
                            src="https://www.douyu.com/favicon.ico"
                            alt="斗鱼"
                        />
                        <span className="streamer-platfrom-name">斗鱼</span>
                    </div>
                );
            }
            case PLATFROM_LIST.DOUYIN: {
                return (
                    <div className="streamer-platfrom douyin">
                        <img
                            className="streamer-platfrom-icon"
                            src="https://lf1-cdn-tos.bytegoofy.com/goofy/ies/douyin_web/public/favicon.ico"
                            alt="抖音"
                        />
                        <span className="streamer-platfrom-name">抖音</span>
                    </div>
                );
            }
            case PLATFROM_LIST.KUAISHOU: {
                return (
                    <div className="streamer-platfrom kuaishou">
                        <img
                            className="streamer-platfrom-icon"
                            src="https://www.kuaishou.com/favicon.ico"
                            alt="快手"
                        />
                        <span className="streamer-platfrom-name">快手</span>
                    </div>
                );
            }
            case PLATFROM_LIST.BILIBILI: {
                return (
                    <div className="streamer-platfrom bilibili">
                        <img
                            className="streamer-platfrom-icon"
                            src="https://www.bilibili.com/favicon.ico"
                            alt="哔哩哔哩"
                        />
                        <span className="streamer-platfrom-name">哔哩哔哩</span>
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        <div className="comment-list-wrapper">
            <div className="comment-list-header">
                {mode === "streamer" && streamer ? (
                    <div className="comment-streamer">
                        {getPlatform(streamer)}
                        <div className="streamer-name">{streamer.nickname}</div>
                    </div>
                ) : (
                    <div></div>
                )}
                <div className="comment-toolbar">
                    {mode !== "my" && (
                        <ToggleBar
                            label="筛选"
                            selectedKey={curSender}
                            items={SENDER_FILTER}
                            onChange={handleFilterSender}
                        />
                    )}
                    {mode === "my" && (
                        <ToggleBar
                            label="筛选"
                            selectedKey={curStatus}
                            items={STATUS_FILTER}
                            onChange={handleFilterStatus}
                        />
                    )}
                    {mode !== "my" && (
                        <ToggleBar
                            label="排序"
                            selectedKey={curEmoji}
                            items={EMOJI_SORTER}
                            onChange={handleSortEmoji}
                        />
                    )}
                </div>
            </div>

            <div className="comment-list-container" ref={scrollRef}>
                <div className="comment-list-body">
                    {(commentList || []).map((comment) => (
                        <div key={comment.comment_id} className="comment-card">
                            {mode === "my" && getStatusLabel(comment)}
                            <div className="card-header">
                                <div className="card-title">
                                    {comment.title || "无标题"}
                                </div>
                                <div className="card-subtitle">
                                    <div className="card-name">
                                        {comment.nickname || "无名氏"}
                                    </div>
                                    <div className="card-time">
                                        {formatDate(comment.created_at)}
                                    </div>
                                </div>
                            </div>
                            <div className="card-content">
                                {comment.content}
                            </div>
                            <div className="card-footer">
                                {mode !== "my" ? (
                                    getEmojiList(comment)
                                ) : (
                                    <div></div>
                                )}
                                {mode === "my" && getActionList(comment)}
                            </div>
                            <div className="card-sep" />
                            {comment.is_new === 1 && <div className="card-sign-new" />}
                            {/* {comment.forward && <div className="card-sign-forward" />} */}
                        </div>
                    ))}
                </div>
            </div>
            <div className="comment-list-footer">
                { total > 0 && <Pager page={page} total={total} onJump={handleJump} /> }
            </div>
            {mode === "my" && (
                <div className="comment-add-content comment-form">
                    <div className="field">
                        <label className="label" htmlFor="add-form-title">
                            标题
                        </label>
                        <input
                            id="add-form-title"
                            className={`title ${addTitleError ? 'error' : ''}`}
                            placeholder="标题文字25个字"
                            value={addTitle}
                            onChange={(ev) => {
                                const value = ev.target.value;
                                setAddTitle(value);
                                setAddTitleError(value.length < 1 || value.length > 25);
                            }}
                        ></input>
                        <span className={`count ${addTitle.length > 25 ? 'error' : ''}`}>{addTitle.length}/25</span>
                    </div>
                    <div className="field">
                        <label className="label" htmlFor="add-form-content">
                            内容
                        </label>
                        <textarea
                            id="add-form-content"
                            className={`content ${addContentError ? 'error' : ''}`}
                            placeholder={`1. 通过世界留言本讲述关于你的故事。\n2. 发布留言后请耐心等待审核，审核通过后方可查看。\n3. 每日最多可以发布10条留言。（请勿发布涉及政治、色情、有悖社会公序良俗，不符合社会主义核心价值观等违反法律、法规的相关留言）`}
                            value={addContent}
                            onChange={(ev) => {
                                const value = ev.target.value;
                                setAddContent(value);
                                setAddContentError(value.length < 1 || value.length > 1000);
                            }}
                        ></textarea>
                        <span className={`count ${addContent.length > 1000 ? 'error' : ''}`}>{addContent.length}/1000</span>
                    </div>
                    <div
                        className={`btn-primary btn-submit ${
                            addTitle.length < 1 || addContent.length < 1 ||
                            addTitle.length > 25 || addContent.length > 1000
                                ? "disabled"
                                : ""
                        }`}
                        onClick={() => {
                            handleConfirmAdd();
                        }}
                    >
                        发布
                    </div>
                </div>
            )}
            {loading && <Loading />}
            {mode === "my" && (
                <Modal
                    visible={deleteModalVisible}
                    content={
                        <div className="message-modal">
                            <div className="message-modal-content">
                                <div className="message-modal-message">确定要删除这条留言</div>
                            </div>
                            <div className="message-modal-toolbar">
                                <div className="btn-primary" onClick={() => {
                                    handleConfirmDelete();
                                }}>确定</div>
                                <div className="btn-primary" onClick={() => {
                                    handleCancelDelete();
                                }}>取消</div>
                            </div>
                        </div>
                    }
                ></Modal>
            )}
            {mode === "my" && (
                <Modal
                    visible={editModalVisible}
                    content={
                        <div className="edit-modal">
                            <div className="edit-modal-header">
                                <div
                                    className="btn-close"
                                    onClick={() => {
                                        handleCancelEdit();
                                    }}
                                />
                                <div className="page-title" />
                            </div>
                            <div className="edit-modal-body comment-form">
                                <div className="field">
                                    <label className="label" htmlFor="edit-form-title">
                                        标题
                                    </label>
                                    <input
                                        id="edit-form-title"
                                        className={`title ${editTitleError ? 'error' : ''}`}
                                        placeholder="标题文字25个字"
                                        value={editTitle}
                                        onChange={(ev) => {
                                            const value = ev.target.value;
                                            setEditTitle(value);
                                            setEditTitleError(value.length < 1 || value.length > 25);
                                        }}
                                    ></input>
                                    <span className={`count ${editTitle.length > 25 ? 'error' : ''}`}>{editTitle.length}/25</span>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor="edit-form-content">
                                        内容
                                    </label>
                                    <textarea
                                        id="edit-form-content"
                                        className={`content ${editContentError ? 'error' : ''}`}
                                        placeholder={`1. 通过世界留言本讲述关于你的故事。 \n2. 发布留言后请耐心等待审核，审核通过后方可查看。 \n3. 每日最多可以发布10条留言。（请勿发布涉及政治、色情、有悖社会公序良俗，不符合社会主义核心价值观等违反法律、法规的相关留言。）`}
                                        value={editContent}
                                        onChange={(ev) => {
                                            const value = ev.target.value;
                                            setEditContent(value);
                                            setEditContentError(value.length < 1 || value.length > 1000);
                                        }}
                                    ></textarea>
                                    <span className={`count ${editContent.length > 1000 ? 'error' : ''}`}>{editContent.length}/1000</span>
                                </div>
                                <div
                                    className={`btn-primary btn-submit ${
                                        editTitle.length < 1 ||
                                        editContent.length < 1 ||
                                        editTitle.length > 25 ||
                                        editContent.length > 1000
                                            ? "disabled"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        handleConfirmEdit();
                                    }}
                                >
                                    发布
                                </div>
                            </div>
                        </div>
                    }
                ></Modal>
            )}
            {mode === "my" && forwardCommentRef.current && (
                <Modal
                    visible={forwardModalVisible}
                    content={
                        <div className="forward-modal">
                            <div className="forward-modal-header">
                                <div
                                    className="btn-close"
                                    onClick={() => {
                                        handleCancelForward();
                                    }}
                                />
                                <div className="page-title" />
                            </div>
                            <div className="forward-modal-body">
                                <StreamerList
                                    mode="forward"
                                    comment={forwardCommentRef.current}
                                ></StreamerList>
                            </div>
                        </div>
                    }
                ></Modal>
            )}
        </div>
    );
}
