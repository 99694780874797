import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import './Message.scss';

function Message(props) {
    const { id, text, handleClose, delay = 1000 } = props;
    const [show, setShow] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShow(true);
            setTimeout(() => {
                setShow(false);
                setTimeout(() => {
                    handleClose(id);
                }, 300)
            }, 300 + delay)
        }, 1)
    }, [id, delay, handleClose]);

    return (
        <div className={`message-wrapper ${show ? 'show' : ''}`}>
            <div className="message-content">{text}</div>
        </div>
    )
}

function MessageContainer(props) {
    useEffect(() => {
        console.log('-------', props.list);
    }, [props])

    return (
        <div className="message-contaner">
            {props.list.map(item => <Message key={item.id} id={item.id} text={item.text} delay={item.delay} handleClose={props.handleClose} />)}
        </div>
    )
}

class MessageManager {
    constructor() {
        const messageRoot = document.createElement('div');
        messageRoot.className = 'message-root';
        document.body.appendChild(messageRoot)
        this.list = [];
        this.root = createRoot(messageRoot);
        this.id = 0;
    }

    handleClose = (id) => {
        this.list = this.list.filter(t => t.id !== id);
        this.root.render(<MessageContainer list={this.list} handleClose={this.handleClose} />);
    }

    info(text, delay = 1000) {
        this.list.push({
            id: this.id++,
            text,
            delay
        });
        this.root.render(<MessageContainer list={this.list} handleClose={this.handleClose} />);
    }
}

export default new MessageManager();