import { Link } from 'react-router-dom';
import CommentList from '../../components/comment-list/CommentList';

import './Publish.scss'

export default function PagePublish() {

    return (
        <div className="page-publish page-container">
            <div className="page-wrapper">
                <div className="page-publish-header">
                    <Link className="btn-back" to="/" />
                    <div className="page-title" />
                </div>
                <div className="page-publish-content">
                    <CommentList mode="my"></CommentList>
                </div>
            </div>
        </div>
    )
}