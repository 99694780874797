const rootURL = process.env.REACT_APP_BASE_URL;
const VERSION = `/v1`;
console.log({ rootURL, VERSION });

function AxiosCreate(instance, token) {
  // 添加请求拦截器
  instance.interceptors.request.use(
    function (config) {
      config.data.token = token;
      config.url = `${rootURL}${VERSION}${config.url}`;
      return config;
    },
    // 对请求错误做些什么
    function (error) {
      return Promise.reject(error);
    }
  );
  // 添加响应拦截器
  instance.interceptors.response.use(function (response) {
    return response;
  });
}
export default AxiosCreate;
